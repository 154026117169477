import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  HStack,
  VStack,
  IconButton,
  Text,
  useToast,
  Spacer,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import TagService from "../services/TagService";

const TagEditModal = ({ isOpen, onClose }) => {
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [selectedTagId, setSelectedTagId] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      fetchTags();
    }
  }, [isOpen]);

  const fetchTags = async () => {
    try {
      const response = await TagService.getAll();
      setTags(response.data);
    } catch (error) {
      console.error("Failed to fetch tags:", error);
      toast({
        title: "Error fetching tags.",
        description: "Unable to fetch tags from the server.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleAddOrUpdateTag = async () => {
    if (!tagName.trim()) return;

    try {
      if (selectedTagId) {
        await TagService.update(selectedTagId, { name: tagName });
      } else {
        await TagService.create({ name: tagName });
      }
      fetchTags();
      setTagName("");
      setSelectedTagId(null);
      toast({
        title: selectedTagId ? "Tag Updated" : "Tag Added",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to add or update tag:", error);
      toast({
        title: "Error",
        description: selectedTagId
          ? "Failed to update tag."
          : "Failed to add tag.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSelectTag = (tag) => {
    setTagName(tag.name);
    setSelectedTagId(tag.id);
  };

  const handleDeleteTag = async (id) => {
    try {
      await TagService.delete(id);
      fetchTags();
      toast({
        title: "Tag Deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to delete tag:", error);
      toast({
        title: "Error deleting tag",
        description: "Failed to delete tag from the server.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setTags([]);
        setTagName("");
        setSelectedTagId(null);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Tags</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            {tags.map((tag) => (
              <HStack key={tag.id} spacing={4} width="100%">
                <Text flex={1}>{tag.name}</Text>
                <Spacer />
                <IconButton
                  aria-label="Edit tag"
                  icon={<EditIcon />}
                  colorScheme="blue"
                  onClick={() => handleSelectTag(tag)}
                />
                <IconButton
                  aria-label="Delete tag"
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  onClick={() => handleDeleteTag(tag.id)}
                />
              </HStack>
            ))}
            <Input
              placeholder="Tag Name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="green"
            onClick={handleAddOrUpdateTag}
          >
            {selectedTagId ? "Update Tag" : "Add Tag"}
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setTags([]);
              setTagName("");
              setSelectedTagId(null);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

TagEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TagEditModal;
