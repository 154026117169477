import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";

const ImagePreviewModal = ({ imageSrc }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Preview image"
        icon={<SearchIcon />}
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        variant="solid" // ボタンに背景色を適用するためのバリアント
        backgroundColor="blue.500" // 背景色
        color="white" // アイコンの色
        size="sm"
        onClick={() => onOpen()}
        zIndex="1"
        _hover={{ bg: "blue.600" }}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding="0">
            <Image
              src={imageSrc}
              alt="Preview"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
ImagePreviewModal.propTypes = {
  imageSrc: PropTypes.string.isRequired,
};

export default ImagePreviewModal;
