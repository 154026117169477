import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function Sidebar({ knowledges }) {
  // // 仮のナレッジ一覧データ
  // const knowledges = [
  //   { id: 1, title: "Knowledge 1" },
  //   { id: 2, title: "Knowledge 2" },
  //   // 他のナレッジデータ...
  // ];

  return (
    <div>
      {knowledges.map((knowledge) => (
        <div key={knowledge.id}>
          <Link to={`/knowledges/${knowledge.id}`}>{knowledge.title}</Link>
        </div>
      ))}
    </div>
  );
}
Sidebar.propTypes = {
  knowledges: PropTypes.array.isRequired,
};

export default Sidebar;
