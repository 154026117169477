import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  CheckboxGroup,
  Stack,
  Checkbox,
  Box,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import TagService from "../services/TagService";
import ImageService from "../services/ImageService";
import ImagePreviewModal from "./ImagePreviewModal";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToHtml } from "draft-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const KnowledgeEditModal = ({ isOpen, onClose, currentKnowledge, onSave }) => {
  const [localKnowledge, setLocalKnowledge] = useState({
    title: "",
    content: "",
    tags: [],
    // images: [],
    ...currentKnowledge,
    images: Array.isArray(currentKnowledge?.images)
      ? currentKnowledge.images
      : [],
  });
  const [allTags, setAllTags] = useState([]);
  useEffect(() => {
    if (isOpen) {
      fetchAllTags();
    }
    //   setLocalKnowledge(
    //     currentKnowledge || { title: "", content: "", tags: [], images: [] },
    //   );
    // }, [isOpen, currentKnowledge]);
    setLocalKnowledge({
      id: currentKnowledge?.id || null,
      title: currentKnowledge?.title || "",
      content: currentKnowledge?.content || "",
      tags: currentKnowledge?.tags || [],
      images: Array.isArray(currentKnowledge?.images)
        ? currentKnowledge.images
        : [],
    });
  }, [isOpen, currentKnowledge]);

  const fetchAllTags = async () => {
    try {
      const response = await TagService.getAll();
      setAllTags(response.data);
    } catch (error) {
      console.error("Failed to fetch tags:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalKnowledge({ ...localKnowledge, [name]: value });
  };

  const handleTagChange = (selectedTagIds) => {
    const selectedTags = allTags.filter((tag) =>
      selectedTagIds.includes(String(tag.id)),
    );
    setLocalKnowledge({ ...localKnowledge, tags: selectedTags });
  };

  // 画像ファイルが選択された時のハンドラ
  const handleImageChange = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }

    try {
      const response = await ImageService.upload(formData);
      // アップロード後の画像URLをlocalKnowledgeのimagesに追加
      const uploadedImages = response.data.images.map((image) => ({
        url: image.url, // 仮にレスポンスの画像オブジェクトにurlプロパティがあるとします
        // 他に必要なプロパティがあればここに追加
      }));
      setLocalKnowledge((prev) => ({
        ...prev,
        images: [...prev.images, ...uploadedImages],
      }));
    } catch (error) {
      console.error("Image upload failed:", error);
    }
  };

  const handleDeleteImage = (index) => {
    // 指定されたインデックスの画像を除外して新しい配列を作成
    const updatedImages = localKnowledge.images.filter((_, i) => i !== index);
    // localKnowledgeのimagesを更新
    setLocalKnowledge({ ...localKnowledge, images: updatedImages });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(currentContent));
    setLocalKnowledge((prevKnowledge) => ({
      ...prevKnowledge,
      content: html,
    }));
  }, [editorState]);

  useEffect(() => {
    if (currentKnowledge && currentKnowledge.content) {
      const blocksFromHtml = htmlToDraft(currentKnowledge.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [currentKnowledge]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent size="full">
        <ModalHeader>
          {currentKnowledge ? "Edit Knowledge" : "Add Knowledge"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                name="title"
                value={localKnowledge.title}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Content</FormLabel>
              <div className="editor">
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={setEditorState}
                  localization={{
                    locale: "ja",
                  }}
                />
              </div>
            </FormControl>
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <CheckboxGroup
                name="tags"
                colorScheme="green"
                value={localKnowledge.tags.map((tag) => String(tag.id))}
                onChange={handleTagChange}
              >
                <Stack>
                  {allTags.map((tag) => (
                    <Checkbox
                      key={tag.id}
                      id={`tag-${tag.id}`}
                      value={String(tag.id)}
                    >
                      {tag.name}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>

            <FormControl>
              <FormLabel> Images</FormLabel>
              <Input type="file" multiple onChange={handleImageChange} />
              <Stack direction="row" spacing={4} mt={4}>
                {localKnowledge.images.map((image, index) => (
                  <Box
                    key={index}
                    position="relative"
                    boxSize="100px"
                    overflow="hidden"
                  >
                    <Image
                      src={`https://tec.bunkakobo.site/${image.url}`}
                      alt={`Uploaded image ${index}`}
                      boxSize="100%"
                      objectFit="cover"
                    />
                    <IconButton
                      aria-label="Delete image"
                      icon={<CloseIcon />}
                      size="sm"
                      position="absolute"
                      right="0"
                      top="0"
                      colorScheme="red"
                      onClick={() => handleDeleteImage(index)}
                    />
                    <ImagePreviewModal
                      imageSrc={`https://tec.bunkakobo.site/${image.url}`}
                    />
                  </Box>
                ))}
              </Stack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="green"
            mr={3}
            onClick={() => onSave(localKnowledge)}
          >
            {currentKnowledge ? "Update Knowledge" : "Add Knowledge"}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

KnowledgeEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentKnowledge: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default KnowledgeEditModal;
