import axios from "axios";

const API_URL = "https://tec.bunkakobo.site/api/tags";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

class TagService {
  getAll() {
    return axiosInstance.get(API_URL);
  }

  get(id) {
    return axiosInstance.get(`${API_URL}/${id}`);
  }

  create(data) {
    return axiosInstance.post(API_URL, data);
  }

  update(id, data) {
    return axiosInstance.put(`${API_URL}/${id}`, data);
  }

  delete(id) {
    return axiosInstance.delete(`${API_URL}/${id}`);
  }
}

export default new TagService();
