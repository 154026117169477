import axios from "axios";

// 画像関連のAPIエンドポイントのベースURL
const API_URL = "https://tec.bunkakobo.site/api/images";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

class ImageService {
  // 画像のアップロード
  upload(formData) {
    return axiosInstance.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // 特定のKnowledgeに関連する画像の取得
  getByKnowledgeId(knowledgeId) {
    return axiosInstance.get(`/knowledge/${knowledgeId}`);
  }

  // 画像の削除
  delete(imageId) {
    return axiosInstance.delete(`/${imageId}`);
  }

  // 画像の並び替え
  reorder(knowledgeId, orderedImageIds) {
    return axiosInstance.post("/reorder", {
      knowledgeId: knowledgeId,
      orderedImageIds: orderedImageIds,
    });
  }
}

export default new ImageService();
