import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IconButton, Heading, Box, Flex } from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import KnowledgeService from "../services/KnowledgeService";
import PropTypes from "prop-types";

function KnowledgeDetail({
  onEditKnowledge,
  onDeleteKnowledge,
  currentKnowledge,
  setCurrentKnowledge,
}) {
  const { id } = useParams();

  useEffect(() => {
    KnowledgeService.get(id)
      .then((response) => {
        setCurrentKnowledge(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id, setCurrentKnowledge]);

  if (!currentKnowledge) {
    return <div>Loading...</div>;
  }

  return (
    <Box className="main">
      <Flex justify="flex-end">
        <div key={currentKnowledge.id}>
          <IconButton
            icon={<EditIcon />}
            size="sm"
            colorScheme="blue"
            aria-label="Edit Knowledge"
            onClick={() => onEditKnowledge(currentKnowledge)}
            mr={2} // 編集ボタンと削除ボタンの間にマージンを設定
          />
          <IconButton
            icon={<DeleteIcon />}
            size="sm"
            colorScheme="red"
            aria-label="Delete Knowledge"
            onClick={() => onDeleteKnowledge(currentKnowledge.id)}
          />
        </div>
      </Flex>
      <Heading as="h1" mb={8}>
        {currentKnowledge.title}
      </Heading>
      <div dangerouslySetInnerHTML={{ __html: currentKnowledge.content }}></div>
    </Box>
  );
}

KnowledgeDetail.propTypes = {
  onEditKnowledge: PropTypes.func.isRequired,
  onDeleteKnowledge: PropTypes.func.isRequired,
  currentKnowledge: PropTypes.object,
  setCurrentKnowledge: PropTypes.func.isRequired,
};

export default KnowledgeDetail;
