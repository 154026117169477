import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import KnowledgeDetail from "./components/KnowledgeDetail";
import Sidebar from "./components/Sidebar";
import {
  Box,
  Button,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  useDisclosure,
  Spacer,
  IconButton,
  useToast,
  Input,
  Collapse,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import KnowledgeService from "./services/KnowledgeService";
import TagEditModal from "./components/TagEditModal";
import KnowledgeEditModal from "./components/KnowledgeEditModal";

function App() {
  const [knowledges, setKnowledges] = useState([]);
  const [currentKnowledge, setCurrentKnowledge] = useState(null);

  const knowledgeModalDisclosure = useDisclosure();
  const tagModalDisclosure = useDisclosure();
  // const [currentKnowledge, setCurrentKnowledge] = useState({
  //   id: null,
  //   title: "",
  //   content: "",
  //   tags: [],
  //   images: [],
  // });
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    retrieveKnowledges();
  }, []);

  const retrieveKnowledges = () => {
    KnowledgeService.getAll()
      .then((response) => {
        setKnowledges(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filteredKnowledges = knowledges.filter((knowledge) => {
    return (
      knowledge.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      knowledge.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      knowledge.tags.some((tag) =>
        tag.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    );
  });

  const handleEditKnowledge = (knowledge) => {
    setCurrentKnowledge(knowledge);
    knowledgeModalDisclosure.onOpen();
  };

  const handleDeleteKnowledge = async (id) => {
    try {
      await KnowledgeService.delete(id);
      retrieveKnowledges(); // データの再取得
      toast({
        title: "Knowledge deleted",
        description: "The knowledge has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Deletion failed",
        description: "Failed to delete the knowledge.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddKnowledge = () => {
    setCurrentKnowledge(null);
    knowledgeModalDisclosure.onOpen();
  };

  const handleSaveKnowledge = async (knowledge) => {
    try {
      if (knowledge.id) {
        console.log(knowledge);
        await KnowledgeService.update(knowledge.id, knowledge);
      } else {
        await KnowledgeService.create(knowledge);
      }
      retrieveKnowledges(); // データの再取得
      knowledgeModalDisclosure.onClose(); // モーダルを閉じる
    } catch (error) {
      console.log(error);
    }
  };

  const openTagModal = () => {
    tagModalDisclosure.onOpen();
  };
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Router>
      <div>
        <Box className="container" p={5}>
          <Flex justifyContent="space-between" alignItems="center" mb={5}>
            <Link to="/">
              <Text fontSize="2xl">Knowledge Base</Text>
            </Link>
            <Spacer />
            <Box>
              <Input
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <Button colorScheme="blue" onClick={openTagModal} ml={3}>
              Edit Tags
            </Button>
            <Button colorScheme="blue" onClick={handleAddKnowledge} ml={3}>
              Add Knowledge
            </Button>
          </Flex>
          <VStack spacing={4} align="stretch">
            <Box p={5} shadow="md" borderWidth="1px">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="xl">Knowledge List</Text>
                <Button onClick={onToggle} size="sm">
                  {isOpen ? "Hide" : "Show"}
                </Button>
              </Flex>
              <Collapse in={isOpen} animateOpacity>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Tag</Th>
                      <Th>Title</Th>
                      <Th>Content</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredKnowledges.map((knowledge) => (
                      <Tr key={knowledge.id}>
                        <Td>{knowledge.id}</Td>
                        <Td>
                          {knowledge.tags.map((tag) => tag.name).join(", ")}
                        </Td>
                        <Td>{knowledge.title}</Td>
                        <Td>{knowledge.content}</Td>
                        <Td>
                          {" "}
                          <IconButton
                            icon={<EditIcon />}
                            size="sm"
                            colorScheme="blue"
                            aria-label="Edit knowledge"
                            onClick={() => handleEditKnowledge(knowledge)}
                            mr={2} // 編集ボタンと削除ボタンの間にマージンを設定
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            size="sm"
                            colorScheme="red"
                            aria-label="Delete knowledge"
                            onClick={() => handleDeleteKnowledge(knowledge.id)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Collapse>
            </Box>
          </VStack>
          <KnowledgeEditModal
            isOpen={knowledgeModalDisclosure.isOpen}
            onClose={knowledgeModalDisclosure.onClose}
            currentKnowledge={currentKnowledge}
            onSave={handleSaveKnowledge}
          />
          <TagEditModal
            isOpen={tagModalDisclosure.isOpen}
            onClose={tagModalDisclosure.onClose}
          />
        </Box>
      </div>
      <Flex>
        <Box width="20vw" height="100vh" bg="gray.100" p="5">
          <Sidebar knowledges={knowledges} />
        </Box>
        <Box width="80%" p="5">
          <Routes>
            <Route
              path="/knowledges/:id"
              element={
                <KnowledgeDetail
                  onEditKnowledge={handleEditKnowledge}
                  onDeleteKnowledge={handleDeleteKnowledge}
                  currentKnowledge={currentKnowledge}
                  setCurrentKnowledge={setCurrentKnowledge}
                />
              }
            />
          </Routes>
        </Box>
      </Flex>
    </Router>
  );
}

export default App;
